@media all and (min-width: 480px) {
  .ModalNewStudent {
    padding: 15px 0;
  }

  .ModalButton {
    display: flex;
    justify-content: flex-end;
  }

  .ModalNewStudent form {
    margin: 0 auto;
    max-width: 320px;
  }
}