.GroupDetailsSection {
    padding: 30px 30px;
    /*margin-bottom: 30px;*/
}

.GroupDetailsStudentsTable {
    margin-bottom: 50px;
}

.material-icons {
    color: #007bff;
}


.detail-value-d {
  text-align: right;
}

.details-key-d {
  font-weight: 700;
}

.StudentDetailsSection {
    padding: 30px 30px;
}