@media all and (min-width: 480px) {
  .ModalNewGroup {
    padding: 15px 0;
    /*float: right;*/
    /*position: relative;*/
    /*right: 0;*/
    /*margin-right: 0;*/
    /*margin-left: auto;*/
    /*display: block;*/
  }

  .ModalButton {
    display: flex;
    justify-content: flex-end;
  }

  .ModalNewGroup form {
    margin: 0 auto;
    max-width: 320px;
  }
}