@media all and (min-width: 480px) {
  .Groups {
    padding: 5px 0;
  }

  .Groups {
    margin: 0 auto;
    /*width: 70%;*/
  }

  .material-icons {
    color: #3f51b5;
  }

  .professorName {
    color: #3f51b5;
    font-weight: 500;
  }

}