.detail-value-d {
    text-align: right;
}

.details-key-d {
    font-weight: 700;
    color: #555555;
}

.StudentDetailsSection {
    padding: 30px 30px;
}

h3 {
    font-family: "Open Sans", sans-serif;
}