@media all and (min-width: 480px) {
  .Students {
    padding: 5px 0;
  }

  .Students {
    margin: 0 auto;
    /*width: 70%;*/
  }

  .studentsName {
    color: #3f51b5;
    font-weight: 500;
  }
}