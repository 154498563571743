.detail-value-subject {
    text-align: right;
}

.details-key-subject {
    font-weight: 600;
    color: #343434;
}

.SubjectDetailsSection {
    padding: 30px 30px;
}

h3,h5 {
    font-family: "Open Sans", sans-serif;
}